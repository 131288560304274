import { gtag_report_conversion } from '../utils/gtagUtils';

// Функция для добавления обработчиков событий
export function initConversionTracking() {
    // Определяем элементы и их цели
    const elements = [
        { id: 'phone-link-header', sendTo: 'AW-16562295439/6LvPCMT-_fkZEI-lwtk9' },
        { id: 'phone-link-mobile-menu', sendTo: 'AW-16562295439/Qz5JCMf-_fkZEI-lwtk9' },
        { id: 'phone-link-footer', sendTo: 'AW-16562295439/6lyICMr-_fkZEI-lwtk9' },
        { id: 'whatsapp-link-header', sendTo: 'AW-16562295439/3WEuCM3-_fkZEI-lwtk9' },
        { id: 'whatsapp-link-mobile-menu', sendTo: 'AW-16562295439/lQIQCND-_fkZEI-lwtk9' },
        { id: 'whatsapp-link-footer', sendTo: 'AW-16562295439/ZZktCI___fkZEI-lwtk9' },
    ];

    elements.forEach(({ id, sendTo }) => {
        const element = document.getElementById(id) as HTMLAnchorElement;
        if (element) {
            element.addEventListener('click', (event) => {
                gtag_report_conversion(undefined, sendTo);
            });
        }
    });
}
