import delegate from 'delegate';
import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';
import { gtag_report_conversion } from '../utils/gtagUtils';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<
    HTMLFormElement,
    {
        sender: ReturnType<typeof createFormSender>;
        submitFn: EventListener;
        focusFn: EventListener;
        closeDelegation: any;
    }
>();

const showMessage = (form: HTMLFormElement, message = '', success: boolean) => {
    const formMessage = form.querySelector<HTMLElement>('.js-form-message');

    if (formMessage) {
        formMessage.hidden = false;
        formMessage.innerHTML = message;
    }

    if (success) {

        formMessage?.classList.add('is-success');
        formMessage?.classList.remove('is-error');
    } else {
        formMessage?.classList.remove('is-success');
        formMessage?.classList.add('is-error');
    }
};

const hideFormMessages = (form: HTMLFormElement) => {
    const formMessage = form.querySelector<HTMLElement>('.js-form-message');

    if (formMessage) {
        formMessage.hidden = true;
        formMessage.classList.remove('is-error');
    }
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>(
        'input[name="check_val"]',
    );
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(
        container.querySelectorAll<HTMLFormElement>(SELECTOR),
    );

    forms.forEach((form) => {
        let isSubmitting = false;
        let hideTimeout: NodeJS.Timeout;
        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });
        const sender = createFormSender(form, {
            shouldClearInputs: true,
            onBeforeSend: () => {
                clearAntispamInput(form);
            },
            onSuccess: (response) => {
                showMessage(form, response.message, response.success);
                if (form.classList.contains('js-form-order')) {
                    const orderSuccessPopup = document.querySelector<HTMLElement>('.js-order-success-popup')
                    const idContainer = orderSuccessPopup?.querySelector('.js-order-id');

                    if (idContainer && response.data && response.data.order_id) {
                        idContainer.textContent = `№${response.data.order_id}`;
                    }

                    form.dispatchEvent(new Event('success-ordering'));
                }
            },
            onError: (err) => {
                showMessage(
                    form,
                    err.message,
                    false,
                );
            },
            onComplete: () => {
                // clearTimeout(hideTimeout);
                // hideTimeout = setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            const isFormValid = validator.validate();

            if (isFormValid) {
                isSubmitting = true;
                if (form.classList.contains('js-form-order')) {
                    gtag_report_conversion(undefined, 'AW-16562295439/8Y7hCPG8vfkZEI-lwtk9');
                }
                sender.send().finally(() => {
                    isSubmitting = false;
                });
            }
        }

        function onFocus(this: HTMLInputElement) {
            validator.clearInput(this);
        }

        validator.inputs.forEach((input) => {
            input.addEventListener('focus', onFocus);
        });
        form.addEventListener('submit', submitFn);
        const closeDelegation = delegate(
            form,
            '.js-remove-message',
            'click',
            () => {
                clearTimeout(hideTimeout);
                hideFormMessages(form);
            },
        );
        map.set(form, { sender, submitFn, focusFn: onFocus, closeDelegation });
    });
}

function destroy(container: Element | Document = document) {
    const forms = Array.from(
        container.querySelectorAll<HTMLFormElement>(SELECTOR),
    );

    forms.forEach((form) => {
        const data = map.get(form);

        if (data) {
            data.closeDelegation.destroy();
            form.removeEventListener('submit', data.submitFn);
            Array.from(form.querySelectorAll('input')).forEach((input) => {
                input.removeEventListener('focus', data.focusFn);
            });
        }
    });
}

function getInstanceByElement(element: HTMLFormElement) {
    return map.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
