export function gtag_report_conversion(url, send_to, value = null, currency = null) {
    const callback = () => {
        if (url !== undefined) {
            window.location = url;
        }
    };

    const params = {
        send_to: send_to,
        transaction_id: '',
        event_callback: callback,
    };

    if (value !== null) {
        params.value = value;
    }
    if (currency !== null) {
        params.currency = currency;
    }

    gtag('event', 'conversion', params);

    return false;
}
